import type {TranslationKeyWithNoVariables} from '../../locales/common';

export type Translation = Record<string, TranslationKeyWithNoVariables>;
export type Translations = Record<string, Translation>;

export function getPluralKey(key: string, count: number): string {
	const pluralRules = new Intl.PluralRules('en', {type: 'cardinal'});
	return translations[key][pluralRules.select(count === 0 ? 1 : count)];
}
export const translations: Translations = {
	global: {
		// interface elements or general translations share across the app
		admin: 'admin',
		april: 'april',
		august: 'august',
		account: 'myAccount',
		administrationAccess: 'administrationAccess',
		address: 'address',
		amount: 'amount',
		alert: 'alerts',
		all: 'all',
		at: 'at',
		backTo: 'backTo',
		backToContractList: 'backToContractList',
		backToDashboard: 'backToDashboard',
		backToInvoiceList: 'backToInvoiceList',
		begin: 'begin',
		billing: 'billing',
		cancel: 'cancel',
		create: 'create',
		cell: 'cell',
		close: 'close',
		cgu: 'cgu',
		characteristics: 'characteristics',
		checkIn: 'checkIn',
		checkOut: 'checkOut',
		clipboardCopied: 'clipboardCopied',
		contract: 'contract',
		contractEmitter: 'contractEmitter',
		completed: 'completed',
		company: 'company',
		contact: 'contact',
		collaborator: 'myCollaborator',
		createdAt: 'createdAt',
		currentMonth: 'currentMonth',
		currentWeek: 'currentWeek',
		currentYear: 'currentYear',
		customer: 'customer',
		civility: 'civility',
		december: 'december',
		deliveryContact: 'deliveryContact',
		deliverySiteAddress: 'deliverySiteAddress',
		date: 'date',
		days: 'days',
		dataFilteredOn: 'dataFilteredOn',
		due: 'due',
		duration: 'duration',
		durationYear: 'durationYear',
		durationYears: 'durationYears',
		durationMonth: 'durationMonth',
		durationMonths: 'durationMonths',
		durationDay: 'durationDay',
		durationDays: 'durationDays',
		durationHours: 'durationHours',
		done: 'done',
		edit: 'edit',
		email: 'email',
		endDate: 'endDate',
		ends: 'ends',
		equipment: 'equipment',
		error: 'error',
		errorExtranetAdmin: 'errorExtranetAdmin',
		errorOccured: 'errorOccured',
		errorPageNotFoundMessage: 'errorPageNotFoundMessage',
		expires: 'expires',
		february: 'february',
		from: 'from',
		fromDate: 'fromDate',
		fromToPeriod: 'fromToPeriod',
		for: 'for',
		friday: 'friday',
		firstName: 'firstName',
		fleetNumber: 'fleetNumber',
		ht: 'ht',
		informations: 'informations',
		invoice: 'invoice2',
		is: 'is',
		in: 'in',
		individual: 'individual',
		isHeadquarter: 'isHeadquarter',
		issueOrder: 'issueOrder',
		inProgress: 'inProgress',
		january: 'january',
		june: 'june',
		july: 'july',
		last7days: 'last7days',
		last15days: 'last15days',
		lastMonth: 'lastMonth',
		lastWeek: 'lastWeek',
		leasingDelivery: 'leasingDelivery',
		legalNotice: 'legalNotice',
		logistics: 'logisticOrder',
		march: 'march',
		monday: 'monday',
		may: 'may',
		mandatory: 'mandatory',
		mr: 'mr',
		ms: 'ms',
		myDashboard: 'myDashboard',
		next: 'next',
		no: 'no',
		noResult: 'noResult',
		notAvailable: 'notAvailable',
		noData: 'noData',
		noDoc: 'noDoc',
		november: 'november',
		notSpecified: 'notSpecified',
		legalForm: 'legalForm',
		lastName: 'lastName',
		lastUpdate: 'lastUpdate',
		loading: 'loading',
		logout: 'logout',
		login: 'login',
		on: 'on',
		october: 'october',
		open: 'open',
		orderedBy: 'orderedBy',
		quote: 'quote',
		personalAccount: 'personalAccount',
		payer: 'payer',
		professional: 'professional',
		phone: 'phone',
		previous: 'previous',
		reset: 'reset',
		rental: 'rental',
		result: 'result',
		returnOrder: 'returnOrder',
		removeAll: 'removeAll',
		september: 'september',
		siret: 'companyNumberShort',
		serialNumber: 'serialNumber',
		save: 'save',
		saturday: 'saturday',
		sale: 'sale',
		search: 'search',
		send: 'send',
		startDate: 'startDate',
		status: 'status',
		starts: 'starts',
		sunday: 'sunday',
		tacitlyRenewable: 'tacitlyRenewable',
		title: 'title',
		tomorrow: 'tomorrow',
		tooMuchData: 'tooMuchData',
		total: 'total',
		telematic: 'telematic',
		the: 'the',
		the_fem: 'the_fem',
		to: 'at' /* 'à' used for time and recipient*/,
		today: 'today',
		thursday: 'thursday',
		tuesday: 'tuesday',
		toDate: 'toDate',
		username: 'username',
		reference: 'reference',
		realEndDate: 'realEndDate',
		renewalDuration: 'renewalDuration',
		bookingRequest: 'reservation',
		vatIdShort: 'vatIdShort',
		validate: 'validate',
		voice: 'voice',
		yes: 'yes',
		wednesday: 'wednesday',
		withoutTitle: 'withoutTitle'
	},
	account: {
		email: 'email',
		companies: 'companies',
		displayOtherCompanies: 'displayOtherCompanies',
		forgottenPassword: 'forgottenPassword',
		titleForgottenPassword: 'titleForgottenPassword',
		forgottenPasswordSuccessed: 'forgottenPasswordSuccessed',
		identity: 'identity',
		sensitiveActions: 'sensitiveActions',
		updateProfilePictureSuccess: 'updateProfilePictureSuccess',
		updateProfilePictureFailed: 'updateProfilePictureFailed',
		updateOwnProfilePicture: 'updateOwnProfilePicture',
		updatePreferredLangSuccess: 'updatePreferredLangSuccess',
		updatePrefferedLangDesc: 'updatePrefferedLangDesc',
		phone: 'phone',
		profilepicture: 'profilepicture',
		removeProfilePicture: 'removeProfilePicture'
	},
	expires: {
		one: 'expires',
		other: 'expiresPlural'
	},
	starts: {
		one: 'starts',
		other: 'startsPlural'
	},
	ends: {
		one: 'ends',
		other: 'endsPlural'
	},
	day: {
		one: 'day',
		other: 'days'
	},
	hour: {
		one: 'hour',
		other: 'hours'
	},
	minute: {
		zero: 'minute',
		one: 'minute',
		other: 'minutes'
	},
	month: {
		one: 'month',
		other: 'monthPlural'
	},
	second: {
		one: 'second',
		other: 'seconds'
	},
	year: {
		one: 'year',
		other: 'yearsPlural'
	},
	dataTableColumns: {
		actions: 'actions',
		amountTotalInDocumentCurrency: 'amountTotalInDocumentCurrency',
		amountDueInDocumentCurrency: 'amountDueInDocumentCurrency',
		contract: 'contract',
		company: 'company',
		deliveryStatus: 'deliveryStatus',
		deliverySiteAddress: 'deliverySiteAddress',
		contractRelatedObjects: 'contractRelatedObjects',
		email: 'email',
		firstName: 'firstName',
		image: 'image',
		invoicedItem: 'invoicedItem',
		linksAttachments: 'linksAttachments',
		lastName: 'lastName',
		model: 'model',
		position: 'position',
		paidAt: 'paidAt',
		range: 'range',
		nextDueDate: 'nextDueDate',
		status: 'status',
		serviceEmitter: 'serviceEmitter',
		taskSchedulings: 'taskSchedulings',
		username: 'username',
		recipient: 'recipient',
		movementType: 'movementType',
		title: 'title',
		titleAndEquipment: 'titleAndEquipment',
		invoiceOrderedBy: 'invoiceOrderedBy'
	},
	checkout: {
		addPhone: 'addPhone',
		administrativeStepTitle: 'administrativeStepTitle',
		availableForYou: 'availableForYou',
		congratulation: 'congratulation',
		conditions: 'conditions',
		checkoutSuccess: 'checkoutSuccess',
		checkoutSuccessLink: 'checkoutSuccessLink',
		checkoutSuccessMail: 'checkoutSuccessMail',
		deliveryContact: 'deliveryContact',
		deliveryContactUpdateSuccess: 'deliveryContactUpdateSuccess',
		deliverySiteAddress: 'deliverySiteAddress',
		changeContact: 'changeContact',
		changeDeliveryContact: 'changeDeliveryContact',
		contractValidationTitle: 'contractValidationTitle',
		customerConstraintIndication: 'customerConstraintIndication',
		customerOrderFormMandatory: 'customerOrderFormMandatory',
		customerReference: 'customerReference',
		deliveryCommentIndication: 'deliveryCommentIndication',
		extranetValidationTag: 'extranetValidationTag',
		generalRentalConditions: 'generalRentalConditions',
		joinInsurance: 'joinInsurance',
		joinOrderForm: 'joinOrderForm',
		securityDeposit: 'securityDeposit',
		securityDepositTag: 'securityDepositTag',
		seeConditions: 'seeConditions',
		siteInformations: 'siteInformations',
		specifyCustomerConstraints: 'specifyCustomerConstraints',
		specifyDeliveryComment: 'specifyDeliveryComment',
		summaryStepTitle: 'summaryStepTitle',
		termsAcceptance: 'termsAcceptance',
		yourReference: 'yourReference',
		yourPersonal: 'yourPersonal'
	},
	companySwapper: {
		allCompanies: 'allCompanies',
		allCompaniesSelected: 'allCompaniesSelected',
		choiceCompany: 'choiceCompany',
		myCompany: 'myCompany',
		myCompanies: 'myCompanies'
	},

	equipmentView: {
		adblueRemaining: 'adblueRemaining',
		checked: 'checked',
		dataSource: 'dataSource',
		delivery: 'delivery',
		documents: 'equipmentDocuments',
		fuelLevel: 'fuelLevel',
		fuelRemaining: 'fuelRemaining',
		linkedObjects: 'linkedObjects',
		maintenanceBookTitle: 'maintenanceBookTitle',
		measurements: 'measurements',
		noDoc: 'equipmentviewNoDoc',
		nonContractualCharacteristics: 'nonContractualCharacteristics',
		picking: 'picking',
		position: 'position',
		reportBreakdown: 'reportBreakdown',
		sinceContractStart: 'sinceContractStart',
		otherGroup: 'other',
		consumptionGroup: 'consumptionGroup',
		usageGroup: 'usageGroup',
		carbonGroup: 'carbonGroup',
		noLongerAssignedSince: 'noLongerAssignedSince'
	},
	equipmentWidgets: {
		carbonDioxideQuantityTitle: 'carbonDioxideQuantity',
		carbonDioxideQuantityDesc: 'carbonDioxideQuantityDesc',
		carbonDioxideKmRatio: 'carbonDioxideKmRatio',
		carbonDioxideHourRatio: 'carbonDioxideHourRatio',
		fuelUsedHourRatio: 'fuelUsedHourRatio',
		idleTimeRatio: 'idleTimeRatio',
		operatingTimeTitle: 'operatingTimeTitle',
		workingTimeRatio: 'workingTimeRatio',
		dailyHourlyPerformance: 'dailyHourlyPerformance',
		measurementComparisonLabel: 'measurementComparisonLabel',
		fuelUsedEvolution: 'fuelUsedEvolution',
		workingTimeDistribution: 'workingTimeDistribution',
		carbonDioxideByRange: 'carbonDioxideByRange',
		carbonDioxideByEquipment: 'carbonDioxideByEquipment'
	},
	errors: {
		accountWithError: 'accountWithError',
		accountWithoutCompany: 'noCompany',
		accountWithoutRightCompanies: 'noRightCompanies',
		accountWithoutContact: 'noContact',
		tokenExpired: 'tokenExpiredExplanation'
	},

	dashboard: {
		buyParts: 'buyParts',
		buySecondHandEquipment: 'buySecondHandEquipment',
		noLongerDisplay: 'noLongerDisplay',
		notConsulted: 'notConsulted',
		rentEquipment: 'rentEquipment',
		requestIntervention: 'requestIntervention',
		startTour: 'startTour',
		welcomeExtranet: 'welcomeExtranet',
		welcomeExtranetDetails: 'welcomeExtranetDetails'
	},

	filterBar: {
		afterThe: 'afterThe',
		beforeThe: 'beforeThe',
		the: 'the',
		lessThan: 'lessThan',
		greaterThan: 'greaterThan',
		maxLabel: 'maxLabel',
		minLabel: 'minLabel',
		between: 'between',
		invoice: 'invoice2',
		refund: 'refund',
		and: 'and',
		selectedPlural: 'selectedPlural',

		// ---- FilterBar dynamic keys -----
		amount: 'amount',
		amountUntaxed: 'amountUntaxed',
		alertActive: 'alertActive',
		blocked: 'blocked',
		cancelled: 'cancelled',
		completed: 'completed',
		creationDate: 'creationDate',
		contract: 'contract',
		contractViewBookingRequest: 'contractViewBookingRequest',
		deadline: 'deadline',
		deliveryOrderGenerated: 'deliveryOrderGenerated',
		delivered: 'delivered',
		toDeliver: 'toDeliver',
		inDelivery: 'inDelivery',
		toPrepare: 'toPrepare',
		emitter: 'emitter',
		endDate: 'endDate',
		history: 'history',
		invoiceDate: 'invoiceDate',
		dateCreated: 'dateCreated',
		isHeadquarter: 'isHeadquarter',
		inProgress: 'inProgress',
		invoiceStatusReturnOrderGenerated: 'invoiceStatusReturnOrderGenerated',
		invoiceStatusPaid: 'invoiceStatusPaid',
		inactive: 'inactive',
		invoiceEmitter: 'invoiceEmitter',
		make: 'make',
		model: 'model',
		order: 'order',
		ongoing: 'ongoing',
		orderDone: 'orderDone',
		power: 'power',
		quote: 'quote',
		range: 'range',
		reservation: 'reservation',
		returnOrderGenerated: 'returnOrderGenerated',
		recipient: 'recipient',
		startDate: 'startDate',
		status: 'status',
		reference: 'reference',
		validated: 'validated',
		validityDate: 'validityDate',
		movementType: 'movementType',
		shortTermRental: 'shortTermRental'
	},

	form: {
		addPhoneNumber: 'addPhoneNumber',
		actualStartDate: 'actualStartDate',
		actualEndDate: 'actualEndDate',
		choiceFile: 'choiceFile',
		choiceFiles: 'choiceFiles',
		dragAndDrop: 'dragAndDrop',
		dragAndDropDescription: 'dragAndDropDescription',
		incorrectInput: 'incorrectInput',
		isnull: 'isnull',
		isNotAnArray: 'isNotAnArray',
		isNotEditable: 'isNotEditable',
		informationsUpdated: 'informationsUpdated',
		isMandatory: 'isMandatory',
		minLengthViolation: 'minLengthViolation',
		maxLengthViolation: 'maxLengthViolation',
		notInChoice: 'notInChoice',
		patternMismatch: 'patternMismatch',
		phoneNumberInvalid: 'phoneNumberInvalid',
		requestSent: 'requestSent',
		resetFormConfirm: 'resetFormConfirm',
		someFieldsIncorrectlyFilled: 'someFieldsIncorrectlyFilled',
		submitFormConfirm: 'submitFormConfirm',
		sendingRequestFailed: 'sendingRequestFailed',
		updateInformationsFailed: 'updateInformationsFailed',
		interlocutorSuccessMessage: 'interlocutorSuccessMessage',
		passwordsMismatch: 'passwordsMismatch',
		remainingAttemptsError: 'remainingAttemptsError',
		tooManyAttemptsError: 'tooManyAttemptsError',

		// ---- Form dynamic keys (form title) -----
		user: 'user',
		company: 'company',
		equipmentContactRequest: 'equipmentContactRequest',
		equipmentBreakdownRequest: 'equipmentBreakdownRequest',
		contractContactRequest: 'contractContactRequest',
		contractInterruptionRequest: 'contractInterruptionRequest',
		contractUpdateRequest: 'contractUpdateRequest',
		invoiceComplainRequest: 'invoiceComplainRequest',
		logisticsComplainRequest: 'logisticsComplainRequest',
		updateContactForm: 'updateContactForm',
		interlocutorContactRequest: 'interlocutorContactRequest',
		contractQuoteValidation: 'contractQuoteValidation',
		requestAccountDeletion: 'requestAccountDeletion',
		editPassword: 'editPassword',
		editPreferredLang: 'editPreferredLang',

		// ---- Form dynamic keys (fields title) -----
		attachment: 'attachment',
		chooseARecipient: 'chooseARecipient',
		content: 'content',
		desiredEndDate: 'desiredEndDate',
		desiredStartDate: 'desiredStartDate',
		equipmentTakeBackInformations: 'equipmentTakeBackInformations',
		subject: 'subject',
		messageContent: 'messageContent',
		confirmPassword: 'confirmPassword',
		currentPassword: 'currentPassword',
		phone: 'phone',
		recipient: 'recipient',

		// ---- Form dynamic keys (form ui) -----
		sendInProgress: 'sendInProgress'
	},

	htmlPdf: {
		// ---- Contract view dynamic keys ----
		contractViewBookingRequest: 'contractViewBookingRequest',
		contractViewCart: 'contractViewCart',
		contractViewCompleted: 'contractViewCompleted',
		contractViewContractValidated: 'contractViewContractValidated',
		contractViewInProgress: 'contractViewInProgress',
		contractViewInit: 'contractViewInit',
		contractViewQuote: 'contractViewQuote',
		contractViewToPrepare: 'contractViewToPrepare',
		contractViewValidated: 'contractViewValidated',
		contractViewWaintingReturn: 'contractViewWaintingReturn'

		// --- Dynamic subjets keys ---
	},

	contractView: {
		completedOn: 'completedOn',
		contractInProgressFrom: 'contractInProgressFrom',
		isNoLongerValidSince: 'isNoLongerValidSince',
		proposaleMadeOn: 'proposaleMadeOn',
		reservationRequestMadeOn: 'reservationRequestMadeOn',
		renewalExpiresOn: 'renewalExpiresOn',
		validUntil: 'validUntil',
		rentalOffer: 'rentalOffer',
		signedOn: 'signedOn',
		expired: 'expired'
	},

	invoiceView: {
		contracts: 'contracts',
		invoicePendingPayment: 'invoicePendingPayment',
		invoicePaidOn: 'invoicePaidOn',
		invoiceNotPaid:
			'invoiceNotPaid2' /* invoiceNotPaid already used for plural */,
		invoiceMustBePaidBefore: 'invoiceMustBePaidBefore',
		invoiceShouldHaveBeenPaidBefore: 'invoiceShouldHaveBeenPaidBefore',
		sales: 'sales'
	},

	logisticsView: {
		contracts: 'contracts',
		deliveryOrder: 'deliveryOrder'
	},

	document: {
		one: 'document',
		other: 'documents'
	},
	equipment: {
		one: 'equipment2',
		other: 'equipments',
		fleetData: 'fleetData',
		deliverySiteAddress: 'deliverySiteAddress',
		lastKnownGpsPosition: 'lastKnownGpsPosition',
		equipmentDocumentTitle: 'equipmentDocumentTitle',
		fleetMap: 'fleetMap',
		fleetMapIntro: 'fleetMapIntro',
		shortTermRental: 'shortTermRental',
		longTermRental: 'longTermRental',
		fullLongTermRental: 'fullLongTermRental',
		fullShortTermRental: 'fullShortTermRental',
		listingTooltip: 'equipmentListingTooltip',
		equipmentFault: 'equipmentFault',
		eventUrgency: 'eventUrgency',
		eventDescription: 'eventDescription'
	},
	invoice: {
		one: 'invoice2',
		other: 'invoices'
	},
	sale: {
		one: 'sale',
		other: 'sales'
	},
	contract: {
		one: 'contract',
		other: 'contracts'
	},
	service: {
		one: 'service2',
		other: 'services'
	},
	article: {
		one: 'article2',
		other: 'articles'
	},
	result: {
		one: 'result',
		other: 'results'
	},

	listing: {
		articles: 'articles',
		alerts: 'alerts',
		cancelled: 'cancelled',
		contracts: 'contracts',
		contractListingDesc: 'contractListingDesc',
		collaborator: 'collaborators',
		contractValidated: 'contractValidated',
		customerRef: 'customerRef',
		createdAt: 'createdAt',
		deliveryDateRequired: 'deliveryDateRequired',
		equipments: 'equipments',
		fleet: 'fleet',
		hourShort: 'hourShort',
		inLate: 'inLate',
		invoice: 'invoice',
		invoiceDate: 'invoiceDate',
		invoiceListingDesc: 'invoiceListingDesc',
		invoiceStatusReturnOrderGenerated: 'invoiceStatusReturnOrderGenerated',
		invoiceContractReference: 'invoiceContractReference',
		initialDuration: 'initialDuration',
		interventionDateRequire: 'interventionDateRequire',
		interventionNature: 'interventionNature',
		interventionComment: 'interventionComment',
		message: 'message',
		nextDueDate: 'nextDueDate',
		noAttachments: 'noAttachments',
		deliveryOrder: 'deliveryOrder',
		location: 'location',
		deliveryOrderTitle: 'deliveryOrderTitle',
		logisticsListingDesc: 'logisticsListingDesc',
		order: 'order',
		priceTotalSuffix: 'priceTotalSuffix',
		quoteValidated: 'quoteValidated',
		removeFilters: 'removeFilters',
		refund: 'refund',
		rentalDates: 'rentalDates',
		rentalFleet: 'rentalFleet',
		rentalFleetListingDesc: 'rentalFleetListingDesc',
		refineSearch: 'refineSearch',
		sales: 'sales',
		services: 'services',
		totalExcVatPerMonth: 'totalExcVatPerMonth',
		workOrder: 'workOrder',

		// ProjectedConsumption
		projectedConsumption: 'projectedConsumption',
		projectedConsumptionRatio: 'projectedConsumptionRatio',
		hours: 'hourShort',
		kilometers: 'kilometersShort',
		overtimeHourMeter: 'overtime',
		overtimeOdometer: 'extraKilometers',
		overtimeCost: 'additionalCost',

		// ---- Listing repositoryField dynamic keys -----
		fleetNumber: 'fleetNumber',
		serialNumber: 'serialNumber',
		vehicleRegistrationPlate: 'vehicleRegistrationPlate',
		dateOfPutIntoService: 'dateOfPutIntoService',
		hourmeter: 'hourmeter',
		power: 'power',

		// ---- Listing Contract status dynamic keys (for DateBlock) -----
		contractCompleted: 'contractCompleted',
		contractWaintingReturn: 'contractWaintingReturn',
		contractInProgress: 'contractInProgress2',
		contractDelivered: 'contractDelivered',
		contractToPrepare: 'contractToPrepare',
		contractReturnOrderGenerated: 'contractReturnOrderGenerated',
		contractReturned: 'contractReturned'
	},

	login: {
		connectionWith: 'connectionWith',
		createAccount: 'createAccount',
		emailAddress: 'emailAddress',
		enterKey: 'enterKey',
		extranetAuthStrategyFail: 'extranetAuthStrategyFail',
		extranetSupportContact: 'extranetSupportContact',
		inactiveExtranetAccount: 'inactiveExtranetAccount',
		mailBeSentShortly: 'mailBeSentShortly',
		moderationExtranetAccount: 'moderationExtranetAccount',
		loginError: 'loginError',
		login: 'login',
		orPress: 'orPress',
		password: 'password',
		pleaseCreateAccount: 'pleaseCreateAccount',
		signIn: 'signIn',
		unknownMail: 'unknownMail'
	},

	pagination: {
		display: 'paginationDisplay',
		to: 'paginationTo',
		on: 'paginationOn',
		items: 'paginationItems',
		show: 'paginationShow',
		perPage: 'paginationPerPage',
		previous: 'paginationPrevious',
		next: 'paginationNext'
	},

	search: {
		amountExVat: 'amountExVat',
		amountATI: 'amountATI',
		equipment: 'equipmentSearchbar',
		clearHistory: 'clearHistorySearch',
		deliverySiteAddress: 'deliverySiteAddress',
		invoice: 'invoice2',
		rental: 'rental',
		recentlyConsulted: 'recentlyConsulted',
		refund: 'refund',
		placeholder: 'searchPlaceholder',
		preview: 'preview',
		noData: 'noData',

		// ---- Search dynamic keys (searchBarFilter) -----
		all: 'all',
		contract: 'contract',
		sale: 'sale',
		invoiceSearchbar: 'invoiceSearchbar',
		logisticsSearchbar: 'logisticsSearchbar',
		equipmentSearchbar: 'equipmentSearchbar',
		equipmentAssignment: 'rentalFleet'
	},

	siteSelector: {
		currentInstance: 'currentInstance'
	},

	subscription: {
		confirmDeletion: 'confirmSubscriptionDeletion'
	},

	sidebar: {
		open: 'openSidebar',
		collapse: 'collapseSidebar',
		close: 'closeSidebar',
		pages: 'pages',
		seeMore: 'seeMore',
		poweredBy: 'poweredBy',

		// ---- Sidebar dynamic keys (sidebarItem) -----
		message: 'sidebarMessages',
		alert: 'alert',
		collaborator: 'collaborators',
		dashboard: 'dashboard',
		home: 'dashboard',
		contract: 'sidebarContracts',
		sale: 'sidebarSales',
		invoice: 'sidebarInvoices',
		logistics: 'logisticsTitle',
		refund: 'sidebarRefunds',
		rentalFleet: 'sidebarRentalFleet',
		fleet: 'sidebarFleet',

		contractQuote: 'quote',
		contractInProgress: 'contractsInProgress',
		contractBookingRequest: 'contractBookingRequests',

		// ---- Sidebar dynamic keys (contract subRoutes) -----
		subscription: 'subscription',
		shortTermRental: 'shortTermRental',
		longTermRental: 'longTermRental',
		maintenance: 'maintenance',
		leasing: 'leasing',
		interEntity: 'interEntity',

		// ---- Sidebar dynamic keys (sale subRoutes) -----
		service: 'service',
		distribution: 'distribution',
		equipment: 'equipment'
	},

	userGuide: {
		// user guide steps
		accountTitle: 'userGuideAccountTitle',
		accountDescription: 'userGuideAccountDescription',
		accountDescription2: 'userGuideAccountDescription2',
		dashboardTitle: 'userGuideDashboardTitle',
		dashboardDescription: 'userGuideDashboardDescription',
		searchBarTitle: 'userGuideSearchBarTitle',
		searchBarDescription: 'userGuideSearchBarDescription',
		sideBarTitle: 'userGuideSideBarTitle',
		sideBarDescription: 'userGuideSideBarDescription',
		welcomeTitle: 'userGuideWelcomeTitle',
		welcomeDescription: 'userGuideWelcomeDescription',
		workspaceTitle: 'userGuideWorkspaceTitle',
		workspaceDescription: 'userGuideWorkSpaceDescription',
		// ui
		step: 'step',
		totalSteps: 'totalSteps'
	},

	register: {
		atLeastLower: 'atLeastLower',
		atLeastCapital: 'atLeastCapital',
		atLeastNumber: 'atLeastNumber',
		atLeast8: 'atLeast8',
		companyNameLabel: 'companyNameLabel',
		confirmExtranetAccountTitle: 'confirmExtranetAccountTitle',
		companyAddressLabel: 'companyAddressLabel',
		checkUpLabel: 'registerCheckUpLabel',
		companyNumber: 'companyNumber',
		confirmExtranetAccountMessage: 'confirmExtranetAccountMessage',
		companyNumberShort: 'companyNumberShort',
		companyStartingActivityDate: 'companyStartingActivityDate',
		followValidationLink: 'followValidationLink',
		frenchCompanyWithSiren: 'frenchCompanyWithSiren',
		pendingExtranetAccountTitle: 'pendingExtranetAccountTitle',
		pendingExtranetAccountMessage: 'pendingExtranetAccountMessage',
		proOrIndividual: 'proOrIndividual',
		sendExtranetValidationCode: 'sendExtranetValidationCode',
		selectPassword: 'selectPassword',
		validationCodeSent: 'validationCodeSent',
		vatIdLong: 'vatIdLong',
		yourBillingAddress: 'yourBillingAddress',
		yourCivility: 'yourCivility',
		yourCompanyPhone: 'yourCompanyPhone',
		yourFirstName: 'yourFirstName',
		yourLastName: 'yourLastName',
		yourMobilePhone: 'yourMobilePhone',
		yourSiretOrCompanyName: 'yourSiretOrCompanyName'
		/* RegisterSummary used global[civility] & globla[status] dynamic keys */
	},

	widget: {
		contractsByStatus: 'contractsByStatus',
		contract: 'contract',
		equipmentsByRange: 'equipmentsByRange',
		equipmentsNumber: 'equipmentsNumber',
		invoicesByType: 'invoicesByType',
		purchasePriceEquipment: 'purchasePriceEquipment',
		salesByType: 'salesByType',
		saveLayout: 'saveLayout',
		updateLayout: 'updateLayout',
		updateLayoutDesc: 'updateLayoutDesc',
		invoiceNotPaid: 'invoiceNotPaid',
		seeInvoices: 'seeInvoices',
		invoiceTotalAmount: 'invoiceTotalAmount',
		months: 'durationMonths',
		interlocutors: 'interlocutors',
		noInterlocutors: 'noInterlocutors',
		askForQuote: 'askForQuote',
		reserveEquipment: 'reserveEquipment',
		rentEquipment: 'rentEquipment',
		contractInProgress: 'contractInProgress',
		contractsInProgress: 'contractsInProgress',
		consulted: 'consulted',
		recentActivities: 'recentActivities',
		equipmentLocation: 'equipmentLocation',
		contractDeliveryOrderGenerated: 'contractDeliveryOrderGenerated',
		hasBeenReconductedSince: 'hasBeenReconductedSince',
		sale: 'sale',
		yourDeliveryOfEquipment: 'yourDeliveryOfEquipment',
		yourRentalEnds: 'yourRentalEnds',
		yourRentalProvisionalDate: 'yourRentalProvisionalDate',
		yourRemoval: 'yourRemoval',
		forTheMorningOf: 'forTheMorningOf',
		forTheAfternoonOf: 'forTheAfternoonOf',
		noInfo: 'noInfo',
		noRental: 'noRental'
	}
};
